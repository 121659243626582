import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { ListPanel } from '@src/cms-components';
import { listPanelMapper } from '@src/cms-components/list-panel/mappers/list-panel.mapper';
import { useCallback, useEffect, useState } from 'react';

const ImageFaqPanelPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>({});

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'list_panel',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;
    setLivePreviewData(response[0][0]);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return (
    !!livePreviewData && <ListPanel {...listPanelMapper(livePreviewData)} />
  );
};

export default ImageFaqPanelPreviewPage;
